<template>
  <div>
    <!-- Início principal -->
    <v-card>
      <v-toolbar flat>
        <v-icon> fas fa-key </v-icon>
        <v-toolbar-title class="pl-4">
          Configurações de chaves - Integrações:
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="(dialogAddToken = true, limparItemSelecionado())"> Adicionar </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col class="px-6 py-6">
            <v-data-table
              dense
              :headers="$store.state.integracao != 2 ? grid.objetos.cabecalho : grid.objetos.cabecalhoBling"
              :items="grid.objetos.items"
              :items-per-page="grid.objetos.porPagina"
              hide-default-footer
              class="elevation-1"
              :loading="grid.objetos.carregando"
            >
              <template v-slot:item.operacoes="{ item }">
                <v-icon size="16" color="green" class="operacoes" @click="abrirModalEditarToken(item)">fas fa-pen</v-icon>
                <v-icon size="16" color="red" class="operacoes" @click="deleteToken(item)">fas fa-trash</v-icon>
              </template>

              <!-- <template v-slot:item.stopEmbalamento="{ item }">
                <v-app-bar-nav-icon>
                  <v-icon size="16" color="red" v-if="item.stopEmbalamento == 0">fas fa-times</v-icon>
                  <v-icon size="16" color="green" v-else>fas fa-check</v-icon>
                </v-app-bar-nav-icon>
              </template> -->

              <template v-slot:item.tipo="{ item }">
                <v-app-bar-nav-icon style="cursor: default;">
                  <v-icon size="16" color="red" v-if="item.tipo == 0">fas fa-times</v-icon>
                  <v-icon size="16" color="green" v-else-if="item.tipo == 1">fas fa-check</v-icon>
                </v-app-bar-nav-icon>
              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Fim principal -->

    <!-- Inicio Modal Add Token -->
      <v-row class="px-9 py-7" justify="end">
        <v-dialog v-model="dialogAddToken" max-width="800">
          <v-card>

            <v-card-title class="text-h5" style="justify-content:center"> Nova Chave </v-card-title>
            <!-- <v-col cols="8" sm="4">
              <v-card-title class="text-h7"> Tipo </v-card-title>
              <v-select
                style="margin-left: 15px; padding: 0;"
                :items="tipoItem"
              ></v-select>
            </v-col> -->
            <v-card-title class="text-h7"> Token Nome </v-card-title>
            <v-card-text style="padding-bottom: 0">
              <v-text-field solo v-model="itemSelecionado.nomeOriginal"></v-text-field>
            </v-card-text>

            <v-card-title class="text-h7" style="padding-top: 0"> Token </v-card-title>
            <v-card-text style="padding-bottom: 0">
              <v-text-field solo v-model="itemSelecionado.valor"></v-text-field>
            </v-card-text>


            <v-col cols="5" v-show="$store.state.integracao === 2">
              <v-card-title class="text-h7" style="padding-top: 0"> Data de início integração </v-card-title>
              <v-card-text style="padding-bottom: 0">
                <!-- <v-text-field solo v-model="itemSelecionado.valor"></v-text-field> -->
                <v-dialog
                  ref="dataIntegracao"
                  v-model="modal.dataIntegracao"
                  :return-value.sync="itemSelecionado.dataIntegracao"
                  persistent
                  width="290px"
                  :retain-focus="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :value="formatDataIntegracao"
                      @click:clear="itemSelecionado.dataIntegracao = null"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="itemSelecionado.dataIntegracao" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal.dataIntegracao = false"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dataIntegracao.save(itemSelecionado.dataIntegracao)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-card-text>
            </v-col>

            <!-- <v-card-title class="text-h7" style="padding-top: 0"> Stop Embalamento </v-card-title> -->
            <!-- <v-checkbox
            v-model="itemSelecionado.tipo"
            label="Embalamento Manual"
            style="padding-left: 25px"
            ></v-checkbox> -->

            <!-- <v-checkbox
            v-model="itemSelecionado.stopEmbalamento1p1"
            label="Stop 1 x 1"
            style="padding-left: 25px; margin-top: 0"
            ></v-checkbox> -->

            <v-card-actions class="alignmentDialog">
              <v-btn
                class="ma-2"
                color="error"
                @click="dialogAddToken = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                class="ma-2"
                color="primary"
                @click="addToken()"
                :disabled="itemSelecionado.nomeOriginal == '' || itemSelecionado.valor == ''"
              >
                Salvar
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>
      </v-row>
      <!-- Fim Modal Add Token -->

      <!-- Modal Alteracao -->
      <v-row class="px-9 py-7" justify="end">
        <v-dialog v-model="dialogEdit" max-width="800">
          <v-card>

            <v-card-title class="text-h5" style="justify-content:center"> Editar Chave </v-card-title>
            <!-- <v-col cols="8" sm="4">
              <v-card-title class="text-h7"> Tipo </v-card-title>
              <v-select
                style="margin-left: 15px; padding: 0;"
                :items="tipoItem"
              ></v-select>
            </v-col> -->
            <v-card-title class="text-h7"> Token Nome </v-card-title>
            <v-card-text style="padding-bottom: 0">
              <v-text-field solo readonly v-model="itemSelecionado.nomeOriginal" disabled></v-text-field>
            </v-card-text>

            <v-card-title class="text-h7" style="padding-top: 0"> Token </v-card-title>
            <v-card-text style="padding-bottom: 0">
              <v-text-field solo v-model="itemSelecionado.valor"></v-text-field>
            </v-card-text>

            <v-col cols="5" v-show="$store.state.integracao === 2">
              <v-card-title class="text-h7" style="padding-top: 0"> Data de início integração </v-card-title>
              <v-card-text style="padding-bottom: 0">
                <!-- <v-text-field solo v-model="itemSelecionado.valor"></v-text-field> -->
                <v-dialog
                  ref="dataIntegracao"
                  v-model="modal.dataIntegracao"
                  :return-value.sync="itemSelecionado.dataIntegracao"
                  persistent
                  width="290px"
                  :retain-focus="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :value="formatDataIntegracao"
                      @click:clear="itemSelecionado.dataIntegracao = null"
                      clearable
                      disabled
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="itemSelecionado.dataIntegracao" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal.dataIntegracao = false"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dataIntegracao.save(itemSelecionado.dataIntegracao)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-card-text>
            </v-col>

            <!-- <v-checkbox
            v-model="itemSelecionado.tipo"
            label="Embalamento Manual"
            style="padding-left: 25px"
            ></v-checkbox> -->

            <!-- <v-checkbox
            v-model="itemSelecionado.stopEmbalamento"
            label="Stop Embalamento"
            style="padding-left: 25px"
            ></v-checkbox>

            <v-checkbox
            v-model="itemSelecionado.stopEmbalamento1p1"
            label="Stop 1 x 1"
            style="padding-left: 25px; margin-top: 0"
            ></v-checkbox> -->

            <v-card-actions class="alignmentDialog">
              <v-btn
                class="ma-2"
                color="error"
                @click="dialogEdit = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                class="ma-2"
                color="primary"
                @click="editarToken()"
                :disabled="itemSelecionado.valor == ''"
              >
                Salvar
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>
      </v-row>
      <!-- Fim Modal Alteracao -->

      <!-- Inicio Modal Exclusao -->
      <v-row class="px-9 py-7" justify="end">
        <v-dialog v-model="dialogDelete" max-width="400">
          <v-card>
            <div
              style="display: flex; justify-content: center; padding: 15px 0 0 0"
            >
              <v-icon x-large>fa-exclamation</v-icon>
            </div>
            <v-card-title class="text-h9 alignmentDialogDelete">
              Warning
            </v-card-title>
            <v-card-text class="alignmentDialogDelete">
              <strong>Deseja realmente excluir a chave?<br>
              Nome: {{ itemSelecionado.nome }}<br>
              Token: {{ itemSelecionado.valor }}</strong></v-card-text
            >
            <v-card-actions class="alignmentDialog">
              <v-btn class="ma-2" color="primary" @click="dialogDelete = false">
                Cancelar
              </v-btn>
              <v-btn class="ma-2" color="error" @click="deleteToken()">
                Excluir
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Fim Modal Exclusao -->

      <!-- Inicio Modal Sucesso ao excluir -->
      <v-row class="px-9 py-7" justify="end">
        <v-dialog v-model="dialogSucessDelete" max-width="400">
          <v-card>
            <div
              style="display: flex; justify-content: center; padding: 15px 0 0 0"
            >
              <v-icon x-large>fa-check</v-icon>
            </div>
            <v-card-title class="text-h5 alignmentDialogDelete">
              Sucesso!
            </v-card-title>
            <v-card-text class="alignmentDialogDelete"
              ><strong>Token excluido com sucesso!!!</strong></v-card-text
            >
            <v-card-actions class="alignmentDialogDelete">
              <v-btn class="ma-2" color="success" @click="(dialogSucessDelete = false, carregarTokens())">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Fim Modal Sucesso ao excluir -->

      <!-- Inicio modal Error salvar informacoes-->
      <v-row class="px-9 py-7" justify="end">
        <v-dialog v-model="dialogError" max-width="400">
          <v-card>
            <div
              style="display: flex; justify-content: center; padding: 15px 0 0 0"
            >
              <v-icon x-large>fa-exclamation</v-icon>
            </div>
            <v-card-title class="text-h5 alignmentDialogDelete">
              Error
            </v-card-title>
            <v-card-text class="alignmentDialogDelete"
              >Erro ao salvar as informações. Tente novamente. Se o erro persistir, contacte um administrador.</v-card-text
            >
            <v-card-actions class="alignmentDialogDelete">
              <v-btn class="ma-2" color="error" @click="dialogError = false">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Fim modal Error salvar informacoes-->

       <!-- Inicio modal Sucesso ao editar -->
      <v-row class="px-9 py-7" justify="end">
        <v-dialog v-model="dialogSucessSave" max-width="400">
          <v-card>
            <div
              style="display: flex; justify-content: center; padding: 15px 0 0 0"
            >
              <v-icon x-large>fa-check</v-icon>
            </div>
            <v-card-title class="text-h5 alignmentDialogDelete">
              Sucesso!
            </v-card-title>
            <v-card-text class="alignmentDialogDelete"
              >Configurações de token salva.</v-card-text
            >
            <v-card-actions class="alignmentDialogDelete">
              <v-btn class="ma-2" color="success" @click="(dialogSucessSave = false, carregarTokens())">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Fim modal Sucesso ao editar-->
  </div>
</template>

<script>
import { baseApiUrlPersys } from "@/global";
import axios from "axios";
import Template from "../components/Template.vue";
import moment from "moment";


export default {
  components: {
    Template,
  },
  name: "Objetos",
  data: () => ({
    dialogSucessDelete: false,
    dialogAddToken: false,
    dialogEdit: false,
    dialogDelete: false,
    dialogSucessSave: false,
    dialogError: false,
    cod_empresa: '',
    itemSelecionado: {
      codigo: null,
      codigoEmpresa: null,
      nome: "",
      valor: "",
      // stopEmbalamento: false,
      // stopEmbalamento1p1: false,
      tipo: false,
      dataIntegracao: '',
    },
    tipoItem: ['Integração', 'Cotação', 'Embalamento'],
    tipoEmbalamento: "",

    grid: {
      objetos: {
        cabecalho: [
          { text: "Nome", value: "nomeOriginal", sortable: false },
          { text: "Token", value: "valor", sortable: false },
          // { text: "Embalamento Manual", value: "tipo", align: "center", sortable: false },
          // { text: "Stop embalamento", value: "stopEmbalamento", sortable: false },
          // { text: "Stop 1 x 1", value: "stop", sortable: false },
          { text: "", value: "operacoes", align: "end", sortable: false },
        ],
        cabecalhoBling: [
          { text: "Nome", value: "nomeOriginal", sortable: false },
          { text: "Token", value: "valor", sortable: false },
          { text: "Data integração", value: "dataIntegracaoFormatada", sortable: false },
          { text: "", value: "operacoes", align: "end", sortable: false },
        ],
        items: [],
        carregando: false,
        porPagina: 10
      },
    },

    modal: {
      dataIntegracao: false,
    }
  }),
  created() {
    this.cod_empresa = Number(this.$store.state.cod_empresa);
  },
  mounted() {
    this.carregarTokens();
  },
  methods: {

    async carregarTokens() {
      this.grid.objetos.items = [];
      this.grid.objetos.carregando = true;
      await axios
        .get(`${baseApiUrlPersys}/token`,  {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
        )
        .then((res) => {
          if(res.data == ''){
            this.grid.objetos.items = [];
            this.grid.objetos.porPagina = 1000;
          }else{
            this.grid.objetos.items = res.data;
            this.grid.objetos.porPagina = 1000;
            for (let i = 0; i < res.data.length; i++) {
              if(this.grid.objetos.items[i].tipo == null){
                this.grid.objetos.items[i].tipo = 0
              }
            }
            let arrTokenWithTypeTokenOne = [];
            this.grid.objetos.items.forEach(item => {
              if(item.tipoToken == 1) {
                if(item.dataIntegracao != null)
                  item.dataIntegracaoFormatada = moment(item.dataIntegracao).format("DD/MM/YYYY");
                arrTokenWithTypeTokenOne.push(item);
              }
              this.grid.objetos.items = arrTokenWithTypeTokenOne;
            });
          }
          this.grid.objetos.carregando = false
        })
        .catch((error) => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
          this.grid.objetos.carregando = false
        })
    },

    async editarToken() {
      if(this.itemSelecionado.tipo == false ){
        this.itemSelecionado.tipo = 0
      }else{
        this.itemSelecionado.tipo = 1
      }
      let hasError = false;
      this.grid.objetos.items.forEach(item => {
        if(item.nomeOriginal != this.itemSelecionado.nomeOriginal && item.valor == this.itemSelecionado.valor) {
          this.$swal({
            title: 'Alerta',
            text: 'Token informado já consta no sistema !',
            icon: 'warning',
            confirmButtonText: 'OK',
            confirmButtonColor: '#62C6A2',
            allowOutsideClick: false,
          });
          hasError = true;
          return;
        }
      });

      if(!hasError) {
        await axios
          .post(`${baseApiUrlPersys}/token`, {
            codigo: this.itemSelecionado.codigo,
            nome: this.itemSelecionado.nomeOriginal,
            valor: this.itemSelecionado.valor,
            codigoEmpresa: this.cod_empresa,
            tipo: this.itemSelecionado.tipo,
            tipoToken: 1,
            dataIntegracao: this.itemSelecionado.dataIntegracao,
          }, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          })
          .then((response) => {
            this.dialogEdit = false;
            this.limparItemSelecionado();
            this.carregarTokens();
            this.$swal({
              title: 'Sucesso!!!',
              text: 'Token editado com sucesso.',
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });
          })
          .catch((error) => {
            console.log(error);
            this.dialogError = true;
          });
      }
    },

    async addToken() {
      if(this.itemSelecionado.tipo == false){
        this.itemSelecionado.tipo = 0
      }else{
        this.itemSelecionado.tipo = 1
      }
      let hasError = false;
      this.grid.objetos.items.forEach(item => {
        if(item.nomeOriginal.toUpperCase() == this.itemSelecionado.nomeOriginal.toUpperCase()) {
          this.$swal({
            title: 'Alerta',
            text: 'Token Nome informado já consta no sistema !',
            icon: 'warning',
            confirmButtonText: 'OK',
            confirmButtonColor: '#62C6A2',
            allowOutsideClick: false,
          });
          hasError = true;
          return;
        }
        if(item.nome.toUpperCase() == this.itemSelecionado.nomeOriginal.toUpperCase()) {
          this.$swal({
            title: 'Alerta',
            text: 'Token Nome informado já consta no sistema !',
            icon: 'warning',
            confirmButtonText: 'OK',
            confirmButtonColor: '#62C6A2',
            allowOutsideClick: false,
          });
          hasError = true;
          return;
        }
        if(item.valor == this.itemSelecionado.valor) {
          this.$swal({
            title: 'Alerta',
            text: 'Token informado já consta no sistema !',
            icon: 'warning',
            confirmButtonText: 'OK',
            confirmButtonColor: '#62C6A2',
            allowOutsideClick: false,
          });
          hasError = true;
          return;
        }
      });

      if(!hasError) {
        await axios
          .post(`${baseApiUrlPersys}/token`, {
            nome: this.itemSelecionado.nomeOriginal,
            valor: this.itemSelecionado.valor,
            codigoEmpresa: this.cod_empresa,
            tipo: this.itemSelecionado.tipo,
            tipoToken: 1,
            dataIntegracao: this.itemSelecionado.dataIntegracao,
          }, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          })
          .then((response) => {
            this.dialogAddToken = false;
            this.limparItemSelecionado();
            this.carregarTokens();
            this.$swal({
              title: 'Sucesso!!!',
              text: 'Token adicionado com sucesso.',
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });
          })
          .catch((error) => {
            this.dialogError = true;
          });
      }
    },

    async deleteToken (item) {
      this.tipoEmbalamento = ""
      if(item.tipo == 0 || item.tipo == null){
        this.tipoEmbalamento = "Não"
      }else{
        this.tipoEmbalamento = "Sim"
      }

      this.$swal({
        title: 'Confirmação',
        html: `Deseja realmente excluir a chave? <br> Nome: ${ item.nome }<br> Token: ${ item.valor }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axios
            .delete(`${baseApiUrlPersys}/token/codigo/${item.codigo}`, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          })
            .then((res) => {
              this.$swal({
              title: 'Sucesso!!!',
              text: 'Token excluído com sucesso.',
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });
              this.carregarTokens();
            });
        }
      });
    },

    limparItemSelecionado(){
      this.itemSelecionado.nome = "";
      this.itemSelecionado.nomeOriginal = "";
      this.itemSelecionado.valor = "";
      this.itemSelecionado.tipo = false;
      this.itemSelecionado.dataIntegracao = "";
    },

    abrirModalEditarToken(item) {
      this.dialogEdit = true;
      this.itemSelecionado = item
    },

    abrirModalApagarToken(item){
      this.dialogDelete = true;
      this.itemSelecionado = item
    },

    fechar() {
      this.$router.push("/");
    },
  },
  computed: {
    formatDataIntegracao() {
      return this.itemSelecionado.dataIntegracao
        ? moment(this.itemSelecionado.dataIntegracao).format("DD/MM/YYYY")
        : "";
    },
  },
  watch: {
    'dialogAddToken' : function (val, oldVal) {
      if (!val) {
        this.carregarTokens();
      }
    },
    'dialogEdit' : function (val, oldVal) {
      if (!val) {
        this.carregarTokens();
      }
    },
  }
};
</script>
<style scoped>
.operacoes {
  cursor: pointer;
  justify-content: flex-end;
  padding-right: 8px;
}
.operacoes::before{
  background-color: transparent !important;
}

.operacoes::after{
  background-color: transparent !important;
}

.alignmentDialog, .btnOperacoes {
  display: flex;
  justify-content: flex-end
}
.alignmentDialogDelete{
  display: flex;
  justify-content: center;
}

.v-icon.v-icon::after{
  display: none;
}

</style>
